<template>
    <v-container fluid style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" xs="12" sm="6" md="2">
                                <v-select solo clearable class="ma-0 pa-0 border-12" hide-details=true v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch Office" dense prepend-icon="mdi-city"></v-select>
                            </v-col>
                            <v-col class="col-6" xs="12" sm="6" md="2">
                                <v-select solo clearable class="ma-0 pa-0 border-12" v-model="machTypeModel" :items="machTypeLists" default="" item-value="mach_type" item-text="descr" label="Mach. Type" @change="(event) => machTypeOnChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </v-col>
                            <v-col class="col-6" xs="12" sm="6" md="2">
                                <v-select solo clearable class="ma-0 pa-0 border-12" v-model="machIDModel" :items="machIDLists" default="" item-value="mach_id" item-text="descr" label="Mach. ID" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-btn class="border-12" color="info" elevation="2" style="padding: 15px;" @click="getData()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>   
            <div class="col-lg-12">
                <div class="card outlined">
                    <v-card-title>
                                            <div class="d-flex w-100">
                                                Result
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                small
                                                color="success"
                                                class="py-5"
                                                @click="dialogAddNew = true"
                                                >
                                                    Create New
                                                </v-btn>
                                            </div>                                            
                                        </v-card-title>
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1 mt-2" page-count="5" :loading="loadingDatatable" dense fixed-header :divider="true" :light="true">
                            <!-- <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="row">
                                        <div class="col-6">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                        <div class="col-6 text-right">
                                            <v-btn class="mt-3" color="green" elevation="2" small rounded @click="dialogAddNew = true" dark>ADD NEW</v-btn>
                                        </div>
                                    </div>
                                </v-toolbar>
                            </template> -->
                            <template v-slot:[`item.showDetails`]="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link>
                                            <v-list-item-title class="ma-2" @click="deleteItem(item)">Delete</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
            <v-dialog v-model="dialogAddNew" max-width="800px">
                <div class="card elevation-5">
                    <v-card class="p-2 rounded-l">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-5">
                                            Master Section
                                        </div>
                                        <v-spacer></v-spacer>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col class="col-6">
                                        <v-select v-model="addOfficeModel" :items="addOfficeLists" default="" item-value="office_id" item-text="office" label="Branch Office" hide-details=true class="ma-0 pa-0 border-12" outlined clearable dense prepend-icon="mdi-city"></v-select>
                                    </v-col>
                                    <v-col class="col-6">
                                        <v-select v-model="addMachTypeModel" :items="addMachTypeLists" default="" item-value="mach_type" item-text="descr" label="Mach. Type" hide-details=true class="ma-0 pa-0 border-12" outlined clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                                    </v-col>
                                    <v-col class="col-6 mt-2">
                                        <v-select v-model="addMachIDModel" :items="addMachIDLists" default="" item-value="mach_id" item-text="descr" label="Mach. ID" hide-details=true class="ma-0 pa-0 border-12" outlined clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                                    </v-col>
                                    <v-col class="col-6 mt-2">
                                        <v-text-field v-model="addSectionIDModel" label="Section ID" hide-details=true class="ma-0 pa-0 border-12" outlined dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-text-field>
                                    </v-col>
                                    <v-col class="col-12 mt-2">
                                        <v-text-field v-model="addSectionDescrModel" label="Descr." hide-details=true class="ma-0 pa-0 border-12" outlined dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col class="col-12 mt-2">
                                    <v-btn class="ma-1" color="red" elevation="2" rounded @click="dialogAddNew = !dialogAddNew" dark>Close</v-btn>
                                    <v-btn class="ma-1" color="primary" elevation="2" rounded @click="submitData()">SUBMIT</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'SMS',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Maintenance',
                disabled: false,
                href: '#',
                },
                {
                text: 'Master Section',
                disabled: true,
                href: '#',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            tab: 'tab-1',
            officeModel: null,
            officeLists: [],
            machTypeModel: null,
            machTypeLists: [],
            machIDModel: null,
            machIDLists: [],
            headers: [
                { text: 'OFFICE', value: 'officeStr', align: 'left' },
                { text: 'MACH. TYPE', value: 'machTypeStr', align: 'left' },
                { text: 'MACH. ID', value: 'machIDStr', align: 'left' },
                { text: 'SECTION ID', value: 'sectionStr', align: 'left' },
                { text: 'DESCR.', value: 'sectionDescrStr', align: 'left' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text lighten-5' },
            ],
            itemLists: [],
            loadingDatatable: false,
            dialogAddNew: false,
            addOfficeModel: null,
            addOfficeLists: [],
            addMachTypeModel: null,
            addMachTypeLists: [],
            addMachIDModel: null,
            addMachIDLists: [],
            addSectionIDModel: null,
            addSectionDescrModel: null

        }
    },
    async mounted(){
        
        this.initialize()
        this.getData()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/maintenance/master-section`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.machTypeLists = res.data.mach_type
                this.machIDLists = res.data.mach_id
                this.addOfficeLists = res.data.office
                this.addMachTypeLists = res.data.mach_type
                this.addMachIDLists = res.data.mach_id

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async getData(){

            this.$store.dispatch('setOverlay', true)

            this.loadingDatatable = true
            this.itemLists = []

            await axios.post(`${process.env.VUE_APP_URL}/api/starcon/maintenance/master-section/getData`, { 

                office: this.officeModel ? this.officeModel : '',
                mach_type: this.machTypeModel ? this.machTypeModel : '',
                mach_id: this.machIDModel ? this.machIDModel : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.itemLists = res.data.result
                this.loadingDatatable = false
                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async submitData(){

            if(this.addOfficeModel == null || this.addOfficeModel == ''){
                Swal.fire({
                        text: 'Please Select Office',
                        icon: 'warning',
                    })
                return false
            }

            if(this.addMachTypeModel == null || this.addMachTypeModel == ''){
                Swal.fire({
                        text: 'Please Select Mach. Type',
                        icon: 'warning',
                    })
                return false
            }

            if(this.addMachIDModel == null || this.addMachIDModel == ''){
                Swal.fire({
                        text: 'Please Select Mach. ID',
                        icon: 'warning',
                    })
                return false
            }

            this.dialogAddNew = false

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/starcon/maintenance/master-section/submitData`, { 

                office: this.addOfficeModel ? this.addOfficeModel : '',
                mach_type: this.addMachTypeModel ? this.addMachTypeModel : '',
                mach_id: this.addMachIDModel ? this.addMachIDModel : '',
                section_id: this.addSectionIDModel ? this.addSectionIDModel : '',
                section_desc: this.addSectionDescrModel ? this.addSectionDescrModel : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)

                this.addOfficeModel = null
                this.addMachTypeModel = null
                this.addMachIDModel = null
                this.addSectionIDModel = null
                this.addSectionDescrModel = null

                if(res.data.result == true)
                {
                    Swal.fire({
                        text: "Success",
                        icon: 'success',
                    })
                    this.getData()
                } else {
                    Swal.fire({
                        text: "This section is already added !",
                        icon: 'error',
                    })
                }

                

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async deleteItem(item){

            console.log(item.office_id);

            Swal.fire({
                text: "Are you sure ?",
                showDenyButton: true,
                confirmButtonText: "YES",
                denyButtonText: "CANCEL"
                    }).then((result) => {
                    if (result.isConfirmed) {
                        
                        axios.post(`${process.env.VUE_APP_URL}/api/starcon/maintenance/master-section/deleteData`, { 

                            office: item.office_id ? item.office_id : '',
                            mach_type: item.mach_type ? item.mach_type : '',
                            mach_id: item.mach_id ? item.mach_id : '',

                        },
                        {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Access-Control-Allow-Origin': '*',
                                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                                } 
                        })
                        .then(res => {
                            
                            this.getData()

                        })
                        .catch(err => {

                            this.$store.dispatch('setOverlay', false)
                            if(err.response.status == '401'){
                                this.$router.push('/');
                            }

                        })

                    }
                });

        }

    },
    watch: {

        
    }
    
}
</script>
